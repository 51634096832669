<template>
  <div>
    <fragment v-if="roles.length > 0">
      <div
        class="dark-overlay"
        :class="{ 'dark-overlay-show': selectRolesShow }"
        @click.stop="togleTabbarRoles"
      ></div>
      <div
        class="tabbar tabbar-overlay"
        :class="{ 'tabbar-overlay-show': selectRolesShow }"
      >
        <div class="logo-wrap">
          <div class="logo">
            <img src="img/images/logo.svg" alt="logo" />
          </div>
        </div>
        <div
          href=""
          class="tabbar-overlay-tab tabbar-profile tabbar-overlay-profile"
        >
          <div
            class="tabbar-profile__inner tabbar-overlay-link tabbar-overlay-profile__inner"
          >
            <div class="tabbar-profile__avatar">
              <img src="img/icons/adding__avatar-user_icon.svg" alt="avatar" />
            </div>
            <div>
              <!--              <p class="tabbar-profile__name-text">{{ currentUserFullName }}</p>-->
              <p class="tabbar-profile__name-text">
                {{ currentUserNumber }}
              </p>
            </div>
          </div>
          <a @click.stop="togleTabbarRoles" class="tabbar-overlay-tab__arrow">
            <img src="/img/icons/arrow_left.svg" alt="" />
          </a>
        </div>
        <a
          v-for="(userRole, indexRole) in roles"
          :key="indexRole"
          href=""
          :class="{
            'tabbar-overlay-tab_active': userRole.active
          }"
          class="tabbar-overlay-tab"
          @click.prevent="
            sendRoleHeader(userRole.role, userRole.id, userRole.organization_id)
          "
        >
          <span
            class="tabbar-overlay-tab__block tabbar-overlay-tab__block_fz-sm"
            >{{ rolesDictionary[userRole.role] }}</span
          >
          <span
            class="tabbar-overlay-tab__block tabbar-overlay-tab__block_fz-md"
          >
            <fragment v-if="joinRoles.indexOf(userRole.role) == '-1'">
              {{ userRole.organization_name }}
            </fragment>
            <fragment v-else-if="userRole.count">
              Объектов: {{ userRole.count }}
            </fragment>
          </span>
          <span class="tabbar-overlay-tab_login">
            <img src="img/icons/btn-login_icon.svg" alt="" />
          </span>
        </a>
      </div>
    </fragment>
    <div class="tabbar" :class="tabbarStatus ? '' : 'disabled'">
      <div class="logo-wrap">
        <div class="logo">
          <img src="/img/images/logo.svg" alt="logo" />
        </div>
      </div>
      <div class="tabbar-nav">
        <fragment v-if="!tabbarChecked">
          <v-progress-circular
            indeterminate
            size="36"
            style="margin: 10px auto 10px auto ; display: block;"
          ></v-progress-circular>
        </fragment>
        <fragment v-else>
          <router-link
            v-for="(link, index) in tabbarLinks"
            :to="link.to"
            :key="index"
            class="tabbar-nav__link"
            active-class="active"
          >
            <img :src="link.src" alt="icon" class="tabbar-nav__icon" />
            <p class="tabbar-nav__text">{{ link.text }}</p>
          </router-link>
        </fragment>
      </div>
      <div class="tabbar__btn">
        <button @click="toggleTabbar" class="btn btn-back">
          <img src="/img/icons/btn-back.svg" alt="icon" />
        </button>
      </div>

      <div class="tabbar-profile">
        <div class="tabbar-profile__inner " @click.stop="togleTabbarRoles">
          <div class="tabbar-profile__avatar">
            <p class="tabbar-profile__avatar-text">
              <img src="img/icons/adding__avatar-user_icon.svg" alt="avatar" />
            </p>
          </div>
          <div class="ml-2">
            <p class="tabbar-profile__name-text">
              {{ currentUserNumber }}
            </p>
            <p class="tabbar-profile__post-text">
              {{ rolesDictionary[currentRole] }}
            </p>
          </div>
        </div>
        <button @click="logout" class="btn btn-logout">
          <img src="/img/icons/btn-logout_icon.svg" alt="icon" />
        </button>
      </div>
      <img
        src="/img/images/tabbar/bg.png"
        alt="tabbar__bg"
        class="tabbar__bg"
      />
    </div>
    <div class="content" :class="tabbarStatus ? '' : 'active'">
      <div class="navbar">
        <div class="navbar__inner">
          <div class="navbar__road">
            <div class="container">
              <div class="breadcrumbs" v-if="readyRoute.ready">
                <router-link
                  v-for="(route, index) in readyRoute.route"
                  :key="index"
                  :to="{
                    path:
                      '/' +
                      (index !== 0
                        ? routeArr
                            .slice(
                              0,
                              /^\d+$/.test(routeArr[index]) ? index + 1 : index
                            )
                            .join('/')
                        : routeArr[0])
                  }"
                  class="breadcrumbs__item"
                  :class="
                    index === routeArr.length - 1
                      ? 'disabled'
                      : '' ||
                        (index !== 0
                          ? /^\d+$/.test(routeArr[index])
                            ? ''
                            : 'hidden'
                          : '')
                  "
                >
                  {{ route }}
                </router-link>

                <div style="height: 20px;"></div>
              </div>
              <div v-else style="height: 20px;" class="breadcrumbs" />
            </div>
          </div>
        </div>
        <div class="navbar__inner">
          <div class="container">
            <div class="navbar__info">
              <div class="flex-left overflow_hidden">
                <div class="d-flex">
                  <h1 class="navbar__title" style="color: #EB5C6D;">
                    <slot name="block" style="color: #EB5C6D;"></slot>
                  </h1>
                  <h1
                    v-if="routeArr == 'generalInformation'"
                    class="navbar__title"
                  >
                    <slot name="navbar-title"> {{ kinderName }}</slot>
                  </h1>
                  <h1 v-else class="navbar__title">
                    <slot name="navbar-title"> {{ activeRoute.text }}</slot>
                  </h1>
                </div>
                <p v-if="routeArr != 'generalInformation'" class="navbar__text">
                  {{ currentTime }}
                </p>
              </div>
              <div class="navbar__btn-wrap">
                <slot name="navbar-btn-wrap"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-wrap">
        <div style="margin-bottom: 80px;" class="list container">
          <slot name="header"></slot>
          <slot name="default"> </slot>
        </div>
        <slot name="list2"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import tabbarSecondLinks from "@/helpers/tabbars/second";
import format from "date-fns/format";
import rolesDictionary from "@/helpers/userInterface";
import { Fragment } from "vue-fragment";
import { omit } from "lodash";
import { routeIdName, routeName } from "@/helpers/breadcrumb";
import router from "@/router";

export default {
  name: "MainLayout",
  components: { Fragment },
  mounted() {
    this.generate();
    this.isDateLoaded = true;
    this.$store.dispatch("fetchCurrentUser");
    this.$store.dispatch("fetchUserRoles").finally(() => {
      this.isDateLoaded = false;
    });
  },
  data() {
    return {
      readyRoute: {
        ready: false,
        route: []
      },
      selectRolesShow: false,
      rolesDictionary: rolesDictionary,
      joinRoles: [5, 10]
    };
  },
  beforeMount() {
    if (this.$mq == "mobile") {
      this.$store.commit("setTabbar", { tabbar: false });
    } else {
      this.$store.commit("setTabbar", { tabbar: true });
    }
  },

  watch: {
    currentRole(role) {
      this.$store.commit("SET_TABBAR_CHECKED", false);
      this.$store.dispatch("fetchTabbarLinks").finally(() => {
        this.$store.commit("SET_TABBAR_CHECKED", true);
        const avTabs = this.tabbarLinks.filter(el =>
          el.userRole.includes(role)
        );
        if (avTabs.length > 0) {
          router.push(avTabs[0].to);
        }
      });
    }
  },

  methods: {
    async generate() {
      const promArr = this.routeArr.map(async (el, index) => {
        if (index % 2 === 1) {
          return await routeIdName(el, this.routeArr[index - 1]);
        } else {
          return routeName(el);
        }
      });
      const arrProm = Promise.all(promArr);
      arrProm.then(arr => {
        this.readyRoute.ready = true;
        this.readyRoute.route = arr;
      });
    },
    sendRoleHeader(role, userGroupId, organization) {
      this.$store
        .dispatch("selectRole", { role, userGroupId, organization })
        .then(() => {
          this.togleTabbarRoles();
          // router.push("/profile");
          const avTabs = this.tabbarLinks.filter(el =>
            el.userRole.includes(role)
          );
          if (avTabs.length > 0) {
            router.push(avTabs[0].to);
          }
        });
    },
    togleTabbarRoles() {
      this.selectRolesShow = !this.selectRolesShow;
    },
    logout() {
      this.$store.dispatch("logout");
    },
    toggleTabbar() {
      this.$store.dispatch("toggleTabbar");
    },
    async reloadPage() {
      return await location.reload();
    }
  },
  computed: {
    routeArr() {
      const arr = this.$route.path.split("/");
      arr.shift();
      return arr;
    },
    kinderName() {
      return this.$store.getters.getDealerInfo?.results?.name;
    },
    route() {
      return omit(this.$route, ["matched"]);
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },

    currentUserNumber() {
      return this.$store.getters.getCurrentUser != undefined
        ? this.$store.getters.getCurrentUser.phone_number
        : null;
    },
    currentUserFullName() {
      return `${this.currentUser?.last_name || " "} ${
        (this.currentUser?.first_name || " ").toLocaleUpperCase()[0]
      }. ${(this.currentUser?.middle_name || " ").toLocaleUpperCase()[0]}`;
    },
    currentUserSmallName() {
      return `${(this.currentUser?.last_name || " ").toLocaleUpperCase()[0]}${
        (this.currentUser?.first_name || " ").toLocaleUpperCase()[0]
      }`;
    },
    currentUserId() {
      return this.$store.getters.getUserId;
    },

    currentUserGroupId() {
      return this.$store.getters.getCurrentUserGroupId;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    activeRoute() {
      const link = this.tabbarLinks.find(el => el.to.name === this.$route.name);
      return link || { text: "" };
    },
    tabbarStatus() {
      return this.$store.getters.getTabbar;
    },
    tabbarLinks() {
      return this.$store.getters.getTabbarLinks;
    },
    tabbarChecked() {
      return this.$store.getters.getTabbarChecked;
    },
    tabbarSecondLinks() {
      return tabbarSecondLinks.filter(el =>
        el.userRole.includes(this.$store.getters.getCurrentRole)
      );
    },
    currentTime() {
      return "UTC" + format(new Date(), "X, Текущее время HH:mm");
    }
  },

  created() {
    this.$store.dispatch("fetchTabbarLinks").finally(() => {
      this.$store.commit("SET_TABBAR_CHECKED", true);
    });
  }
};
</script>

<style scoped>
.hidden {
  display: none;
}
.tabbar-overlay-tab_active {
  background-color: #b8d615;
}
.btn-back {
  z-index: 2;
}
</style>
